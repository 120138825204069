
.App.desktop {
    padding: 0;

    display: flex;
    flex-direction: column;
    min-height: 100vh;
    width: 100%;
}
.btn {
    cursor: pointer;
}
.desktop .body {
    flex: 1;
}
.desktop .container {
    width: 1100px;
    margin: auto;
    text-align: left;
}
.header-desktop-container {
    flex: none;
    padding-top: 16px;
    padding-bottom: 16px;
}
.header-desktop {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 16px;
    margin-top: 8px;
}
.header-desktop .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.header-desktop .logo img {
    width: 160px;
}
.desktop .header-desktop a {
    color: #a37a53;
}
.desktop .header-desktop .nav-label-count {
    background: #4db9b9;
}
.desktop .header-desktop .logo {
    font-weight: 800;
}
.desktop .header-contacts {
    font-weight: 800;
}
.desktop .footer {
    background: #333;
    color: white;
    margin-top: 20px;
    flex: none;
}

.desktop .main-menu {

    border-radius: 6px;
    display: flex;
    flex-direction: row;
    overflow: hidden;
}
.desktop .main-menu .item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: #333;
    color: white;
    line-height: 1.1;
    height: 54px;
}
.desktop .main-menu .item:hover {
    background: #3a3a3a;
    color: white;
}
.desktop .section .element {

}
.desktop .productSlider .productSliderItem {
    width: 20vw;
}
.desktop .productSlider .productSliderItem img {
    width: 20vw;
    height: 20vw;
}
.desktop .basket {
    padding-bottom: 0;
}

.desktop .product-detail {
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.desktop .product-detail .slider {
    width: 50%;
    flex: none;
}
.desktop .product-detail .product-description {
    margin-top: 0px;
}
.desktop .product-detail .product-description .product-name {
    margin-top: 0;
}
.desktop .wrapper {
    margin-left: 0;
    margin-right: 0;
}
.desktop .footer .footer-title {
    margin-top: 16px;
}
.desktop .footer .socials {
    margin: 16px 0 16px 0;
    gap: 10px;
    display: flex;
    flex-direction: row;
}
.desktop .header-delivery-info {
    border-left: #f2f3f5 4px solid;
    padding-left: 20px;
    padding-top: 8px;
    padding: 8px 0 8px 12px;
    margin-left: 10px;
}